export const manifestURLS = [
    {
        key: 'Asian Legacy Library',
        url:
            'https://all-library-manifests.s3.us-west-2.amazonaws.com/ISKS1RC8012.IG.ISKS1RC8012.001.manifest.json',
    },
    {
        key: 'BDRC',
        url:
            'https://iiifpres.bdrc.io/2.1.1/v:bdr:V1NLM1957_I1NLM1957_001/manifest',
    },
]
